import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function DesignBuildPage() {


  return (
    <>
      <Header />
      <div class="bg-primary pt-5 pb-20">
        <div class="container">
          <div class="row">
            <div class="col col-md-auto bg-primary mb-3">
              <p class="text-white point-22">Bid Opportunities</p>
              <h1 class="text-secondary fw-boldest point-42">WAH</h1>

              <div>
                <span class="text-secondary fw-bolder text-uppercase point-16">Forms/Information</span>
                <span class="point-16 fw-bolder text-danger">&nbsp;|&nbsp;</span>
                <span class="text-secondary fw-bolder text-uppercase point-16">Advertise for sub-quotes</span>
              </div>
            </div>
            <div class="col align-self-end mb-3 text-center">
              <span class="text-secondary fw-bolder text-uppercase point-16">ALDOT DBE Directory</span>
              <span class="point-16 fw-bolder text-danger">&nbsp;|&nbsp;</span>
              <span class="text-secondary fw-bolder text-uppercase point-16">B&G</span>
            </div>
            <div class="col col-md-2 text-end">
              <img src="images/WAH-Logo-ALDOT-Approved.png" width="160px" />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-3">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12 col-md-5">
                      <div class="input-group mb-3">
                        <span class="input-group-text text-uppercase text-secondary fw-bold border-0 bg-white rounded-0" id="basic-addon1">Filter:</span>
                        <input type="text" class="form-control bg-white rounded-0 border-bottom border border-0 border-3 border-secondary text-uppercase" placeholder="Worktype" aria-label="Worktype" aria-describedby="basic-addon1" />
                      </div>
                    </div>

                    <div class="col-6 col-md mb-2">
                      <div class="input-group mb-3">
                        <input type="button" class="form-control btn btn-primary text-white text-uppercase me-2 fw-bolder rounded-pill" placeholder="Search" aria-label="Search" value="Search" aria-describedby="basic-addon1" />
                        <input type="button" class="form-control btn btn-link text-uppercase me-2 text-danger text-start" placeholder="Reset" aria-label="Reset" value="Reset" aria-describedby="basic-addon1" />
                      </div>
                    </div>

                    <div class="col">
                      <div class="clearfix">
                        <span class="float-md-end">
                          <span class="point-12 text-black-50 fw-light"><span class="point-18"><i className="bi bi-star fs-6"></i></span>
                            <span class="text-uppercase fw-bold">&nbsp;Save to Dashboard</span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <ul class="list-group py-3">
                      <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer">
                        <div class="d-md-flex justify-content-between align-items-start">
                          <div class="me-auto mb-2">
                            <span class="fw-bold point-22">Project Name Here&nbsp;</span>
                            <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                          </div>
                          <div class="mb-2">
                            <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                          </div>
                        </div>
                        <p class="lh-lg">
                          <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                          text of the printing and typesetting industry.<br />
                          <span class="fw-bold">Subs/Suppliers: Opt In</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">Primes: Advertise for help</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                            help</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="">
                    <ul class="list-group py-3">
                      <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
                        <div class="d-md-flex justify-content-between align-items-start">
                          <div class="me-auto mb-2">
                            <span class="fw-bold point-22">Project Name Here&nbsp;</span>
                            <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                          </div>
                          <div class="mb-2">
                            <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                          </div>
                        </div>
                        <p class="lh-lg">
                          <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                          text of the printing and typesetting industry.<br />
                          <span class="fw-bold">Subs/Suppliers: Opt In</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">Primes: Advertise for help</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                            help</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="">
                    <ul class="list-group py-3">
                      <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
                        <div class="d-md-flex justify-content-between align-items-start">
                          <div class="me-auto mb-2">
                            <span class="fw-bold point-22">Project Name Here&nbsp;</span>
                            <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                          </div>
                          <div class="mb-2">
                            <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                          </div>
                        </div>
                        <p class="lh-lg">
                          <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                          text of the printing and typesetting industry.<br />
                          <span class="fw-bold">Subs/Suppliers: Opt In</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">Primes: Advertise for help</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                            help</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="">
                    <ul class="list-group py-3">
                      <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
                        <div class="d-md-flex justify-content-between align-items-start">
                          <div class="me-auto mb-2">
                            <span class="fw-bold point-22">Project Name Here &nbsp;</span>
                            <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                          </div>
                          <div class="mb-2">
                            <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                          </div>
                        </div>
                        <p class="lh-lg">
                          <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                          text of the printing and typesetting industry.<br />
                          <span class="fw-bold">Subs/Suppliers: Opt In</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold">Primes: Advertise for help</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                          <br class="d-block d-md-none" />
                          <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                            help</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <nav aria-label="Page navigation example" className="clearfix">
                    <ul className="pagination float-end">

                      <li className="page-item">
                        <a className="page-link border-0 fw-bolder" href="#">1</a>
                      </li>
                      <li className="page-item"><a className="page-link border-0" href="#">2</a></li>
                      <li className="page-item"><a className="page-link border-0" href="#">3</a></li>
                      <li className="page-item"><a className="page-link border-0" href="#">Next</a></li>
                    </ul>
                  </nav>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default DesignBuildPage;
