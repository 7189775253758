import React from "react";


export default function ProfileBilling() {
  return (
    <>
      <h2
        class="text-primary fw-bolder point-32 mb-3 text-uppercase"
      >
        Billing
      </h2>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md mb-4 mb-md-0">
            <div
              class="card border-danger bg-light p-2 border-start-only"
            >
              <p class="mb-0 point-14">Current Monthly Bill</p>
              <h3 class="point-18 fw-normal mb-0">$29.99</h3>
              &nbsp;
            </div>
          </div>
          <div class="col-12 col-md mb-4 mb-md-0">
            <div
              class="card border-secondary bg-light p-2 border-start-only"
            >
              <p class="mb-0 point-14">Next Payment Due</p>
              <h3 class="point-18 fw-normal mb-0">
                July 5<sup>th</sup>
              </h3>
              &nbsp;
            </div>
          </div>
          <div class="col-12 col-md mb-4 mb-md-0">
            <div
              class="card border-primary bg-light p-2 border-start-only"
            >
              <p class="mb-0 point-14">Current Package Plan</p>
              <h3 class="point-18 fw-normal mb-0">
                Package #1
              </h3>
              <a href="#" class="text-danger point-14"
              >Change plan</a>
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col">
            <button
              type="submit"
              class="float-end btn btn-danger text-white rounded-5 fw-bold point-14 text-uppercase"
            >
              Add Payment Method
            </button>
            <h3
              class="text-secondary fw-bolder point-16 text-uppercase mb-0 mt-4"
            >
              Payment Methods:
            </h3>
          </div>
        </div>
        <div class="row my-4">
          <div class="table-responsive">
            <table class="table table-responsive">
              <tbody>
                <tr>
                  <td>
                    <div class="small">Visa ending in 1234</div>
                    <div class="text-xs text-muted">
                      Expires 07/2027
                    </div>
                  </td>
                  <td class="text-end">
                    <span
                      class="text-danger fw-bold text-uppercase"
                    >
                      Default
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="small">
                      Mastercard ending in 5678
                    </div>
                    <div class="text-xs text-muted">
                      Expires 04/2024
                    </div>
                  </td>
                  <td class="text-end">
                    <a
                      class="text-secondary fw-bold text-uppercase text-decoration-none"
                      href="#!"
                    >Make Default</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row my-4">
          <div class="col">
            <h3
              class="text-secondary fw-bolder point-16 text-uppercase mb-0 mt-4"
            >
              Billing History:
            </h3>
          </div>
        </div>
        <div class="row my-4">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-responsive">
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>39201</td>
                    <td>07/05/2024</td>
                    <td>$29.99</td>
                    <td>
                      <span class="badge bg-light text-dark"
                      >Pending</span>
                    </td>
                  </tr>
                  <tr>
                    <td>38594</td>
                    <td>06/05/2024</td>
                    <td>$29.99</td>
                    <td>
                      <span class="badge bg-success">Paid</span>
                    </td>
                  </tr>
                  <tr>
                    <td>38223</td>
                    <td>05/05/2024</td>
                    <td>$29.99</td>
                    <td>
                      <span class="badge bg-success">Paid</span>
                    </td>
                  </tr>
                  <tr>
                    <td>38125</td>
                    <td>04/05/2024</td>
                    <td>$29.99</td>
                    <td>
                      <span class="badge bg-success">Paid</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
