import { Link } from 'react-router-dom'
import img from '../assets/footer-img.svg'
export default function Footer() {
  const accessToken = localStorage.getItem("accessToken");
  return (
    <footer className="bg-white mt-auto">
      <div className="bg-primary py-4">
        <div className="container">
          <div className="row">
            <div className="col col-md-6">
            <img src={img} alt="logo"/>
            </div>
            {!accessToken && <div className="col col-md-3">
              <ul className="nav flex-column text-secondary">
                <li className="nav-item">
                  <Link
                    className="nav-link text-secondary fw-semibold red-arrowhead point-18"
                    to="/login"
                  >
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-secondary fw-semibold red-arrowhead point-18"
                    to="/signup"
                  >
                    Sign Up
                  </Link>

                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-secondary fw-semibold red-arrowhead point-18"
                    to="/contactus"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>}
            <div className="col col-md-3">
              <h4 className="text-secondary point-18">Headquarters</h4>
              <p className="text-white point-16">
                2100 Southbridge Pkwy
                <br />
                Birmingham, AL 35209
                <br />
                <a
                  href="mailto:info@contractorcn.com"
                  className="text-white text-decoration-none"
                >
                  info@contractorcn.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-2">
          <div className="col point-16 fw-normal">
            © 2024 Contractors Communication Network All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}
