import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import searchIcon from "../assets/search-icon.svg"

export default function DirectoryPage() {
  return (
    <>
      <Header />
      <div class="bg-primary pt-5 pb-20">
        <div class="container">
          <div class="row">
            <div class="col col-md-auto bg-primary mb-3">
              <p class="text-white point-22"></p>
              <h1 class="text-secondary fw-boldest point-42">Directory</h1>
            </div>
            <div class="col text-end">&nbsp;</div>
          </div>
        </div>
      </div>
      <div class="bg-white py-3">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
                          id="basic-addon1">Filter:</span>
                        <select
                          class="form-select border-top-0 border-start-0 border-end-0 border-3 border-secondary rounded-0"
                          aria-label="Default select example"
                        >
                          <option selected>All</option>
                          <option value="1">Highway</option>
                          <option value="2">Building</option>
                        </select>
                      </div>
                    </div>
                    <div class="col">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control bg-white rounded-0 border-bottom border border-0 border-3 border-secondary text-uppercase"
                          placeholder="County"
                          aria-label="County"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control fw-bolder text-white bg-secondary rounded-0 border border-1 border-secondary placeholder-white point-18"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="button-addon2"
                        />
                        <button
                          class="btn btn-primary text-secondary rounded-0 border-0"
                          type="button"
                          id="button-addon2"
                        >
                          <span class="point-20">
                            <img src={searchIcon} alt="icon" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="clearfix">
                    <span class="float-md-end">
                      <span class="point-12 text-black-50 fw-light"
                      ><span class="point-18"><i className="bi bi-star fs-6"></i></span>
                        <span class="text-uppercase fw-bold"
                        >&nbsp;Save to Dashboard</span></span>
                    </span>
                  </div>

                  <div class="">
                    <ul class="list-group py-3">
                      <li
                        class="list-group-item py-3 mb-3 bg-light border border-1 rounded-0 slideup-p cursor-pointer"
                      >
                        <div class="d-md-flex justify-content-between align-items-start">
                          <div class="me-auto mb-2">
                            <span class="fw-bold point-22">Contractor Name Here </span>
                            <span class="point-22 text-black-50 fw-light"><i className="bi bi-star fs-6"></i>&nbsp;</span>
                            <span class="fw-normal font-sm">Worktype: Concrete</span>
                          </div>
                          <div class="mb-2">

                          </div>
                        </div>
                        <p class="lh-lg">
                          <span class="fw-bold"> Address </span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>

                          <span class="fw-bold">Phone</span>
                          <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                          <span class="fw-bold">Email</span>
                          <br class="" />
                          <span>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>

                  <nav aria-label="Page navigation example" class="clearfix">
                    <ul class="pagination float-end">
                      <li class="page-item">
                        <a class="page-link border-0 fw-bolder" href="#">1</a>
                      </li>
                      <li class="page-item"><a class="page-link border-0" href="#">2</a></li>
                      <li class="page-item"><a class="page-link border-0" href="#">3</a></li>
                      <li class="page-item"><a class="page-link border-0" href="#">Next</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Footer />
    </>
  );
};
