import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import downloadIcon from '../assets/download-icon.svg'
import progrssGraph from '../assets/progress-graph-icon.svg'
import DashboardShortCuts from "../components/DashboardShortCuts";
import directoryImg from '../assets/directoryImg.svg'
import bigImg from '../assets/bid-img.svg'

export default function DotSupportServicePage() {
  
  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">My Dashboard</h1>
            </div>
            <div className="col-6 col-md-auto text-end">
              <img
                src="images/ALDOT-Logo.png"
                width="300px"
                style={{verticalAlign:"baseline",maxWidth:"100%"}}
              />
            </div>
            <div className="col-6 col-md-auto text-end pt-md-4">
              <span
                className="text-secondary fw-bolder text-uppercase point-16 me-md-3"
              >User Profile</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3 clearfix">
        <div className="container mt-n20">
          <div className="row">
            <div className="col-12 col-md-8 pb-4">
              <div
                className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0"
              >
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold"
                      >
                        Alabama UCP market data
                      </h3>
                    </div>
                    <div className="col-auto bg-secondary">
                      <div className="rounded-pill bg-secondary w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <img className="header-icon m-2" src={progrssGraph} alt="icon"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <ul className="mh-20 list-group m-0 p-0">
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        Aldot ucp directory
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        ALL ALDOT CERTIFIED
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        INSTATE ALDOT CERTIFIED
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        BIRMINGHAM AIRPORT AUTHORITY
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        Huntsville International Airport
                      </button>
                    </li>
                    <li className="list-group-item border-0 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                      >
                        Alabama State Port Authority
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <DashboardShortCuts/>
          </div>
          <div className="row">
            <div className="col col-md-4">
              <div
                className="card w-100 bg-secondary rounded-3 shadow-sm mb-4 border-0"
              >
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-white border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold"
                      >
                        Directory
                      </h3>
                    </div>
                    <div className="col-auto bg-primary">
                      <div className="d-flex flex-column justify-content-center">
                      <img src={directoryImg} alt="icon" className="header-icon m-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="">
                    <div className="mt-4">
                      
                      <button
                        type="submit"
                        className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                      >
                        View Directory
                      </button>
                    </div>
                    <div className="clearfix text-white point-12 text-uppercase mb-2">
                      * Section shows based on user profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-md-8">
              <div
                className="card w-100 bg-primary rounded-3 shadow-sm mb-4 border-0"
              >
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-secondary border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-white fw-bold"
                      >
                        Bid Opportunities
                      </h3>
                    </div>
                    <div className="col-auto bg-secondary">
                      <div className="d-flex flex-column justify-content-center">
                      <img src={bigImg} alt="icon" className="header-icon m-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="">
                    <div className="mt-4">
                       <button
                        type="submit"
                        className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                      >
                        DOT
                      </button>

                      <button
                        type="submit"
                        className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                      >
                        State/City/County
                      </button>

                      <button
                        type="submit"
                        className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                      >
                        Design Build
                      </button>

                      <button
                        type="submit"
                        className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                      >
                        Private
                      </button>

                    </div>
                    <div className="clearfix text-white point-12 text-uppercase mb-2">
                      * Section shows based on user profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card w-100 bg-white rounded-3 shadow-sm mb-4 border-0">
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold"
                      >
                        Reports
                      </h3>
                    </div>
                    <div className="col-auto bg-secondary">
                      <div className="rounded-pill bg-secondary w-100">
                        <div className="d-flex flex-column justify-content-center">
                        <img className="header-icon m-2" src={downloadIcon} alt="icon"/>
                         </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <ul className="list-group py-3 pe-3 mh-20">
                    <li
                      className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0"
                    >
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col-12 col-md point-18 text-primary fw-bold"
                          >
                            Workshop Training Records
                          </div>

                          <div
                            className="col-12 col-md point-18 text-primary text-end"
                          >
                            <a
                              href="#"
                              className="text-decoration-none btn-with-svg point-18 btn rounded-pill btn-primary " style={{width:'32%'}} 
                            >Download
                            <img style={{width:'21%'}} src={downloadIcon} alt="icon"/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
