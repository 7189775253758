import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function FormsPage() {
  return (
    <>
      <Header />
      <div class="bg-primary py-3">
        <div class="container">
          <div class="row mt-10">
            <div class="col col-md-auto">
              <p class="text-white point-22">Bid Opportunities</p>
              <h1 class="text-secondary fw-boldest point-42">Resources</h1>
            </div>
            <div class="col">&nbsp;</div>
          </div>
        </div>
      </div>
      <div class="bg-white py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="fw-bold point-32">Federal Resources</h2>
              <p class="point-18">
              </p>
            </div>
            <div class="col--12">
              <div class="" role="group" aria-label="Vertical button group">
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-light py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="fw-bold point-32">State Resources</h2>
              <p class="point-18">
              </p>
              <p>
              </p>
            </div>
            <div class="col-12">
              <div class="" role="group" aria-label="Vertical button group">
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  Personal net worth statement
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  Dbe certification procedures
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  No change affidavit
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  Uniform certification application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="fw-bold point-32">
                Disadvantaged Business Enterprise Resources
              </h2>
              <p class="point-18">
              </p>
              <p>

              </p>
            </div>
            <div class="col-12">
              <div class="" role="group" aria-label="Vertical button group">
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
                <button
                  type="button"
                  class="me-2 btn btn-primary my-1 px-4 py-2 text-uppercase rounded-5"
                >
                  LINK here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-primary py-5">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <p class="text-white fw-semibold">
                For more information, please contact:
              </p>
            </div>
            <div class="col-6">
              <p class="text-white">
                Full Name<br />
                Email Address Here<br />
                Phone Here
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
