import React, { useState } from "react";
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";
import CountryFilter from "../components/Filters/CountryFilter";
import Pagination from "../components/Pagination";

function BidMatching() {
  const [pageLimit, setPageLimit] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCountrySelect = (countryCode) => {
    console.log('Selected country code:', countryCode);
  };
  const handleWorkTypeSelect = (workType) => {
    console.log('Selected work type:', workType);
  }
  return (
    <>
      <div className="row mb-2">
        <div className="col-12 col-md-auto">
          <span
            className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
            id="basic-addon1">Filter:</span>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div>
              <WorkTypeFilter onSelectWorkType={handleWorkTypeSelect} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div>
              <CountryFilter onSelectCountry={handleCountrySelect} />
            </div>
          </div>
        </div>
        <div className="col-6 col-md mb-2">
          <input type="date"
            className="form-control fw-bold bg-white rounded-0 border-bottom border border-0 border-3 border-secondary text-uppercase"
            aria-label="Date" aria-describedby="basic-addon1" />
        </div>
      </div>


      <div className="">
        <ul className="list-group py-3">
          <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
            <div className="d-md-flex justify-content-between align-items-start">
              <div className="me-auto mb-2">
                <span className="fw-bold point-22">Backfill</span>
              </div>
              <div className="mb-2">
                <a
                  className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer">View</a>
              </div>
            </div>
          </li>
          <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
            <div className="d-md-flex justify-content-between align-items-start">
              <div className="me-auto mb-2">
                <span className="fw-bold point-22">Base</span>
              </div>
              <div className="mb-2">
                <a
                  className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer">View</a>
              </div>
            </div>
          </li>
          <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
            <div className="d-md-flex justify-content-between align-items-start">
              <div className="me-auto mb-2">
                <span className="fw-bold point-22">Bridge</span>
              </div>
              <div className="mb-2">
                <a
                  className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer">View</a>
              </div>
            </div>

          </li>
          <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
            <div className="d-md-flex justify-content-between align-items-start">
              <div className="me-auto mb-2">
                <span className="fw-bold point-22">Clearing</span>
              </div>
              <div className="mb-2">
                <a
                  className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer">View</a>
              </div>
            </div>
          </li>
          <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
            <div className="d-md-flex justify-content-between align-items-start">
              <div className="me-auto mb-2">
                <span className="fw-bold point-22">Concrete</span>
              </div>
              <div className="mb-2">
                <a
                  className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer">View</a>
              </div>
            </div>
          </li>
          <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
            <div className="d-md-flex justify-content-between align-items-start">
              <div className="me-auto mb-2">
                <span className="fw-bold point-22">Culvert</span>
              </div>
              <div className="mb-2">
                <a
                  className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer">View</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="col">
        <button className="btn btn-secondary rounded-5 fw-semibold text-uppercase point-18">
          View All
        </button>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onPageChange={handlePageChange}
        />
      </div>

    </>
  );
}

export default BidMatching;
