import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";
import CountryFilter from "../components/Filters/CountryFilter";

function Map() {
  const navigate = useNavigate();
  mapboxgl.accessToken = "pk.eyJ1IjoidmVscGVvIiwiYSI6ImNseGJlOWZmejNjbzEya3BvNzlmb2l5ajgifQ.ptn6HoALCcLbCPIjYyokng";

  const mapContainer = useRef(null);
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-86.8104, 33.5186],
      zoom: 10,
    });

    // Add markers to the map
    const markers = [
      [-86.626621, 33.610049],
      [-86.798969, 33.507719],
      [-86.665866, 33.674883],
      [-86.830265, 33.442118],
      [-86.707098, 33.428288],
    ];

    markers.forEach(([lng, lat]) => {
      new mapboxgl.Marker({ color: "#0D2C4F" })
        .setLngLat([lng, lat])
        .addTo(map);
    });

    // Clean up on component unmount
    return () => map.remove();
  }, []);
  const handleCountrySelect = (countryCode) => {
    console.log('Selected country code:', countryCode);
  };
  const handleWorkTypeSelect = (workType) => {
    console.log('Selected work type:', workType);
  }
  return (
    <>
      <Header />

      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-auto bg-primary mb-3">
              <p className="text-white point-22">Bid Opportunities</p>
              <h1 className="text-secondary fw-boldest point-42">DOT</h1>

              <div>
                <span className="text-white fw-bolder text-uppercase point-16" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot', { state: { data: 0 } })}>Proposals</span>
                <span className="point-16 fw-bolder text-danger mx-2">&nbsp;|&nbsp;</span>
                <span className="text-secondary fw-bolder text-uppercase point-16" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot', { state: { data: 1 } })}>Bid Matching</span>
                <span className="point-16 fw-bolder text-danger">&nbsp;|&nbsp;</span>
                <span className="text-secondary fw-bolder text-uppercase point-16" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot', { state: { data: 2 } })}>Agency Information</span>
              </div>
            </div>
            <div className="col text-end">
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="clearfix mb-2">
                <span className="me-3 point-16">
                  <span className="text-secondary fw-bolder text-uppercase point-16 me-2 ">Bidders List</span>

                  <span className="point-16 fw-bolder text-uppercase text-secondary me-2 ">As Read Bids</span>

                  <span className="text-secondary fw-bolder text-uppercase point-16">Notice to Contractors</span>

                  <br className="d-block d-md-none" />
                  <span className="float-md-end">
                    <span className="point-12 text-black-50 fw-light  text-secondary"><span className="point-18 text-secondary"><i className="bi bi-star"></i></span>
                      <span className="text-uppercase fw-bold text-secondary">&nbsp;Save to Dashboard</span></span>
                  </span>
                  <br className="d-block d-md-none" />
                  <span className="float-md-end me-3 point-16">
                    <span className="text-secondary fw-bolder text-uppercase point-16">View as:</span>
                    <span className="point-16 fw-bolder text-uppercase text-secondary" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot')}>&nbsp;List |&nbsp;</span>
                    <span className="text-white fw-bolder text-uppercase point-16">Map</span>
                  </span>
                </span></div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div id="map-wrapper" className="map-wrapper vh-50 mt-n20 bg-white shadow-sm mb-5" style={{ overflow: 'hidden' }}>
                <div id="map" ref={mapContainer}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    backgroundColor: '#f5f1f0'
                  }}
                ></div>
                <div id="compass-rose" className="p-4 bg-white w-25 clearfix" style={{ zIndex: 10 }}>
                  <h4 className="text-secondary text-uppercase point-18">Filter:</h4>
                  <div>
                    <WorkTypeFilter onSelectWorkType={handleWorkTypeSelect} />
                  </div>
                  <div>
                    <CountryFilter onSelectCountry={handleCountrySelect} />
                  </div>
                  <input type="date" className="form-control fw-bold bg-white rounded-0 border-bottom border border-0 border-3 border-secondary text-uppercase" aria-label="Date" aria-describedby="basic-addon1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Map;
