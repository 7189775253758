import { Suspense } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import './styles/main.scss';
import { Toaster } from "react-hot-toast";
import Router from './routes';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'mapbox-gl/dist/mapbox-gl.css';

function App() {
  return (
    <Suspense fallback="loading">
      <BrowserRouter>
        <div>
        <Router/>
          <Toaster position="top-right" />
        </div>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;