import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/Dashboard";
import TermsAndCondition from "./pages/TermsConditionPage";
import ContactUsPage from "./pages/ContactUsPage";
import Dot from "./pages/DotPage";
import Map from "./pages/Map";
import StateCityCountryPage from "./pages/StateCityCountryPage";
import DesignBuildPage from "./pages/DesignBuildPage";
import PrivatePage from "./pages/Private";
import DirectoryPage from "./pages/Directory";
import CalanderPage from "./pages/Calander";
import LoginPage from "./pages/Auth/Login";
import UserProfile from "./pages/Profile/UserProfile";
import FormsPage from "./pages/Forms";
import UploadingRequest from "./pages/UploadingRequest";
import MarketingPage from "./pages/Marketing";
import DotSupportServicePage from "./pages/DotSupportService";
import WAH from "./pages/WAH";
import CCN from "./pages/CCN";

export default function Router() {
    const routes = useRoutes([
        { path: "/", element: <Home /> },
        { path: "/signup", element: <Signup /> },
        { path: "/login", element: <LoginPage /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/termsandcondition", element: <TermsAndCondition /> },
        { path: "/contactus", element: <ContactUsPage /> },
        { path: "/dot", element: <Dot /> },
        { path: "/map", element: <Map /> },
        { path: "/statecitycountry", element: <StateCityCountryPage /> },
        { path: "/designbuild", element: <DesignBuildPage /> },
        { path: "/private", element: <PrivatePage /> },
        { path: "/directory", element: <DirectoryPage /> },
        { path: "/calander", element: <CalanderPage /> },
        { path: "/profile", element: <UserProfile /> },
        { path: "/forms", element: <FormsPage /> },
        { path: "/uplodingRequest", element: <UploadingRequest /> },
        { path: "/marketing", element: <MarketingPage /> },
        { path: "/dotsupportservice", element: <DotSupportServicePage /> },
        { path: "/wah", element: <WAH /> },
        { path: "/ccn", element: <CCN /> }
    ]
    );
    return routes;
}