import React, { useEffect, useState } from "react";
import AxiosInstance from "../../utils/axiosInstance";
import { setLocalStoarge } from "../../common/allFunctions";


export default function WorkTypeFilter({ onSelectWorkType, reset }) {
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [list, setList] = useState([]);
  
  useEffect(() => {
    const ListDataFromLocalStorage = JSON.parse(localStorage.getItem("workTypeList")|| "[]");
    setList(ListDataFromLocalStorage);
    if(ListDataFromLocalStorage.length===0){
      getWorkType();
    }      
  }, []);

  useEffect(() => {
    if (reset) {
      setSelectedWorkType('')
    }
  }, [reset]);

  const transformData = (items) =>
    items
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

  const getWorkType = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_WORKTYPE_HIGHWAY}`)
      .then(response => {
        if (response.data?.data) {
          setList(transformData(response.data?.data));
          setLocalStoarge("workTypeList", JSON.stringify(transformData(response.data?.data)))
        }
      })
      .catch(error => {
        console.log(error?.message);
      });
  }
  const handleChange = (event) => {
    const country = event.target.value;
    setSelectedWorkType(country);
    onSelectWorkType(country);
  };

  return (
    <select
      className="form-select border-top-0 border-start-0 border-end-0 border-3 border-secondary rounded-0"
      aria-label="Default select example"
      value={selectedWorkType}
      onChange={handleChange}
    >
      <option value="" disabled>Work Type</option>
      {list.length > 0 && list?.map((options, i) => (
        <option key={i} value={options.value}>
          {options.label}
        </option>
      ))}
    </select>
  );
};