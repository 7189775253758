import Footer from "../components/Footer";
import opptunityImg from '../assets/opportunity-img.svg'
import directoryImg from '../assets/directoryImg.svg'
import bigImg from '../assets/bid-img.svg'
import relationshipImg from '../assets/relationship-img.svg'
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import DashboardShortCuts from "../components/DashboardShortCuts";


export default function Dashboard() {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <div className="bg-primary pt-5 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-auto bg-primary mb-3">
                            <h1 className="text-secondary fw-boldest point-42">My Dashboard</h1>
                        </div>
                        <div className="col-12 col-md text-md-end pt-md-4">
                            <span className="text-secondary fw-bolder text-uppercase point-16 me-md-3">User Profile</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-3 clearfix">
                <div className="container mt-n20">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0">
                                <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2">
                                    <div className="row g-0">
                                        <div className="col g-0">
                                            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
                                                My Saved Opportunities
                                            </h3>
                                        </div>
                                        <div className="col-auto bg-secondary">
                                            <div className="rounded-pill bg-secondary w-100">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <img src={opptunityImg} alt="icon" className="header-icon m-2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <div className="">
                                        <ul className="list-group py-0 mh-20 pe-2">
                                            <li className="list-group-item p-0 mb-2 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                                <div className="container-fluid g-0">
                                                    <div className="row m-0 g-0">
                                                        <div className="col p-2">
                                                            <div className="point-16 lh-sm text-primary fw-bold">
                                                                Worktype : Minor Structural Concrete
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Project Goal : 8%
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Lorem Ipsum is simply dummy text of the printing
                                                                and typesetting industry.
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="red-arrow-bg p-4 d-block h-100 w-100 text-decoration-none">&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item p-0 mb-2 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                                <div className="container-fluid g-0">
                                                    <div className="row m-0 g-0">
                                                        <div className="col p-2">
                                                            <div className="point-16 lh-sm text-primary fw-bold">
                                                                Worktype : Minor Structural Concrete
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Project Goal : 8%
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Lorem Ipsum is simply dummy text of the printing
                                                                and typesetting industry.
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="red-arrow-bg p-4 d-block h-100 w-100 text-decoration-none">&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item p-0 mb-2 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                                <div className="container-fluid g-0">
                                                    <div className="row m-0 g-0">
                                                        <div className="col p-2">
                                                            <div className="point-16 lh-sm text-primary fw-bold">
                                                                Worktype : Minor Structural Concrete
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Project Goal : 8%
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Lorem Ipsum is simply dummy text of the printing
                                                                and typesetting industry.
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="red-arrow-bg p-4 d-block h-100 w-100 text-decoration-none">&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item p-0 mb-2 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                                <div className="container-fluid g-0">
                                                    <div className="row m-0 g-0">
                                                        <div className="col p-2">
                                                            <div className="point-16 lh-sm text-primary fw-bold">
                                                                Worktype : Minor Structural Concrete
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Project Goal : 8%
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Lorem Ipsum is simply dummy text of the printing
                                                                and typesetting industry.
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="red-arrow-bg p-4 d-block h-100 w-100 text-decoration-none">&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item p-0 mb-2 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                                <div className="container-fluid g-0">
                                                    <div className="row m-0 g-0">
                                                        <div className="col p-2">
                                                            <div className="point-16 lh-sm text-primary fw-bold">
                                                                Worktype : Minor Structural Concrete
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Project Goal : 8%
                                                            </div>
                                                            <div className="point-16 lh-sm text-primary">
                                                                Lorem Ipsum is simply dummy text of the printing
                                                                and typesetting industry.
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="red-arrow-bg p-4 d-block h-100 w-100 text-decoration-none">&nbsp;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DashboardShortCuts/>
                    </div>
                    <div className="row">
                        <div className="col col-md-4">
                            <div className="card w-100 bg-secondary rounded-3 shadow-sm mb-4 border-0">
                                <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-white border-2">
                                    <div className="row g-0">
                                        <div className="col g-0">
                                            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
                                                Directory
                                            </h3>
                                        </div>
                                        <div className="col-auto bg-primary">
                                            <div className="d-flex flex-column justify-content-center">
                                                <img src={directoryImg} alt="icon" className="header-icon m-2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <div className="">
                                        <div className="mt-4">
                                            <button type="submit" className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                                                onClick={() => navigate("/directory")}
                                            >
                                                View Directory
                                            </button>
                                        </div>
                                        <div className="clearfix text-white point-12 text-uppercase mb-2">
                                            * Section shows based on user profile
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-md-8">
                            <div className="card w-100 bg-primary rounded-3 shadow-sm mb-4 border-0">
                                <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-secondary border-2">
                                    <div className="row g-0">
                                        <div className="col g-0">
                                            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-white fw-bold">
                                                Bid Opportunities
                                            </h3>
                                        </div>
                                        <div className="col-auto bg-secondary">
                                            <div className="d-flex flex-column justify-content-center">
                                                <img src={bigImg} alt="icon" className="header-icon m-2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <div className="">
                                        <div className="mt-4">
                                            <button type="submit" className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                                                onClick={() => navigate("/dot")}
                                            >
                                                DOT
                                            </button>

                                            <button type="submit" className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                                                onClick={() => navigate('/statecitycountry')}
                                            >
                                                State/City/County
                                            </button>

                                            <button type="submit" className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                                                onClick={() => navigate('/designbuild')}
                                            >
                                                Design Build
                                            </button>

                                            <button type="submit" className="btn btn-outline-secondary border-3 text-secondary rounded-5 fw-bold point-16 px-4 me-3 text-uppercase mb-4"
                                                onClick={() => navigate('/private')}
                                            >
                                                Private
                                            </button>
                                        </div>
                                        <div className="clearfix text-white point-12 text-uppercase mb-2">
                                            * Section shows based on user profile
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0">
                                <div className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2">
                                    <div className="row g-0">
                                        <div className="col g-0">
                                            <h3 className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold">
                                                My Saved Contacts
                                            </h3>
                                        </div>
                                        <div className="col-auto bg-secondary">
                                            <div className="rounded-pill bg-secondary w-100">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <img src={relationshipImg} alt="icon" className="header-icon m-2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <ul className="list-group py-3 pe-3 mh-20">
                                        <li className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md point-18 text-primary fw-bold">
                                                        Company Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        First Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Last Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Phone Number
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Email Address
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md point-18 text-primary fw-bold">
                                                        Company Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        First Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Last Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Phone Number
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Email Address
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md point-18 text-primary fw-bold">
                                                        Company Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        First Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Last Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Phone Number
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Email Address
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md point-18 text-primary fw-bold">
                                                        Company Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        First Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Last Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Phone Number
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Email Address
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md point-18 text-primary fw-bold">
                                                        Company Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        First Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Last Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Phone Number
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Email Address
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12 col-md point-18 text-primary fw-bold">
                                                        Company Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        First Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Last Name
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Phone Number
                                                    </div>
                                                    <div className="col-12 col-md point-18 text-primary">
                                                        Email Address
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

