import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProfileBilling from "./ProfileBilling";
import ProfileSecurity from "./ProfileSecurity";
import { Link } from "react-router-dom";
import AxiosInstance from "../../utils/axiosInstance";
import Select from 'react-select';


export default function UserProfile() {
  const [activeTab, setActiveTab] = useState(0);
  const [highway, setHighway] = useState([]);
  const [building, setBuilding] = useState([]);
  const [options, setOptions] = useState([]);
  const [workTypeList, setWorkTypeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (selected) => {
    const workTypeData = transformWorkTypeData(workTypeList, selected);
    // setFormData((prevData) => ({ ...prevData, 'worktypes': workTypeData }));
    setSelectedOptions(selected);
  };
  useEffect(() => {
    const fetchAPIs = async () => {
      try {
        getWorkType();
        getState();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchAPIs();
  }, []);
  const transformData = (items) =>
    items.map(({ id, name }) => ({
      value: id,
      label: name,
    })
    );

  const transformWorkTypeData = (originalData, selectedValues) => {
    const selectedIds = selectedValues.map(item => item.value);

    const result = Object.keys(originalData).reduce((acc, key) => {
      acc[key] = originalData[key]
        .filter(item => selectedIds.includes(item.id))
        .map(item => item.id);
      return acc;
    }, {});

    return result;
  };
  const getWorkType = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_WORKTYPE_API}`)
      .then(response => {
        setWorkTypeList(response.data?.data)
        setHighway(transformData(response.data?.data?.highway));
        setBuilding(transformData(response.data?.data?.building));
        setOptions([
          ...transformData(response.data?.data?.highway),
          ...transformData(response.data?.data?.building),
        ])
      })
      .catch(error => {
        console.log(error?.message);
      });
  }
  const getState = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_STATE_API}`)
      .then(response => {
        setStateList(transformData(response?.data?.data));
      })
      .catch(error => {
        console.log(error?.message);
      });
  }
  const handleStateChange = (selected) => {
    const stateData = selected.map(item => item.value);
    // setFormData((prevData) => ({ ...prevData, 'state_id': stateData }));
    setSelectedState(selected);
};
  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">User Profile</h1>
            </div>
            <div className="col-12 col-md text-md-end pt-md-4">
              <span className="text-secondary fw-bolder text-uppercase point-16 me-3"
              >User Profile</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3 clearfix">
        <div className="container mt-n20">
          <div className="row">
            <div className="col-12">
              <div className="card w-100 rounded-3 shadow-sm mb-4">
                <div className="card-body">
                  <div className="container-fluid g-0">
                    <div className="row g-0">
                      <div className="col-12 col-md-4 bg-light p-4 dotPage">
                        <ul className="nav nav-tabs list-group border-0 bg-light">
                          <li className="nav-item">
                            <Link
                              className={`nav-item list-group-item border-0 bg-light text-uppercase fw-bold point-16  ${activeTab === 0 ? 'text-danger' : 'text-black'}`}
                              title="Profile"
                              onClick={() => handleTabClick(0)}
                            >
                              Profile Settings
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-item list-group-item border-0 bg-light text-uppercase fw-bold point-16  ${activeTab === 1 ? 'text-danger' : 'text-black'}`}
                              title="billing"
                              onClick={() => handleTabClick(1)}
                            >
                              Billing
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-item list-group-item border-0 bg-light text-uppercase fw-bold point-16  ${activeTab === 2 ? 'text-danger' : 'text-black'}`}
                              title="Security"
                              onClick={() => handleTabClick(2)}
                            >
                              Security
                            </Link>
                          </li>
                        </ul>
                      </div>


                      {activeTab === 0 && <div className={`col-12 col-md-8 bg-white p-4 ${activeTab === 0 ? 'show active' : ''}`} id="Profile">
                        <h2
                          className="text-primary fw-bolder point-32 mb-3 text-uppercase"
                        >
                          My Profile Settings
                        </h2>
                        <form>
                          <div className="row mb-4">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="Username"
                                aria-label="Username"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="First name"
                                aria-label="First name"
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="Last name"
                                aria-label="Last name"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="Email"
                                aria-label="Email"
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="Phone Number"
                                aria-label="Phone Number"
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <h3
                              className="text-secondary fw-bolder point-16 text-uppercase mb-0 mt-4"
                            >
                              Company Information:
                            </h3>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="Company Name"
                                aria-label="Company Name"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="Street"
                                aria-label="Street"
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="City"
                                aria-label="City"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="State"
                                aria-label="State"
                              />
                            </div>
                            <div className="col-12 col-md mb-4">
                              <input
                                type="text"
                                className="form-control border border-secondary border-3 rounded-0"
                                placeholder="Zip"
                                aria-label="Zip"
                              />
                            </div>
                          </div>
                          <div className="row my-2 d-none d-md-block">&nbsp;</div>
                          <div className="col-12 col-md-12 mb-12">
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                              <span className="fw-bold point-16 text-uppercase">Select Work Type</span>
                            </label>
                            <Select
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                              })}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: '#9FAFCA'
                                }),
                                dropdownIndicator: (provided, state) => ({
                                  ...provided,
                                  color: '#a71e1e',
                                })
                              }}
                              options={options}
                              isMulti
                              value={selectedOptions}
                              onChange={handleChange}
                              placeholder="Select work type..."
                              isSearchable
                            />
                          </div>
                          <div className="col-12 col-md-12 mb-12 mt-4 mb-3">
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                              <span className="fw-bold point-16 text-uppercase"
                              >What states are you located in?</span>
                            </label>
                            <Select
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                              })}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: '#9FAFCA'
                                }),
                                dropdownIndicator: (provided, state) => ({
                                  ...provided,
                                  color: '#a71e1e'
                                })
                              }}
                              options={stateList}
                              isMulti
                              value={selectedState}
                              onChange={handleStateChange}
                              placeholder="Select states..."
                              isSearchable
                            />

                          </div>
                          <button
                            type="submit"
                            className="btn btn-secondary rounded-5 fw-bold point-18 text-uppercase"
                          >
                            Save Changes
                          </button>
                        </form>
                      </div>}

                      {activeTab === 1 && <div className={`col-12 col-md-8 bg-white p-4 ${activeTab === 1 ? 'show active' : ''}`} id="billing">
                        <ProfileBilling />
                      </div>}
                      {activeTab === 2 && <div className={`col-12 col-md-8 bg-white p-4 ${activeTab === 2 ? 'show active' : ''}`} id="Security">
                        <ProfileSecurity />
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
