import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CountryFilter from "../components/Filters/CountryFilter";
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";
import AxiosInstance from "../utils/axiosInstance";
import Pagination from "../components/Pagination";
import toast from "react-hot-toast";
import loader from '../assets/loader.svg'
import favoriteImg from '../assets/bookmark-favorite.svg'
import ListOfBidModel from "../components/Model/ListOfBidModel";
import ViewPlanHolderModel from "../components/Model/ViewPlanHolderModel";

function ProposalDot() {

  const [nestedActiveTab, setActiveTab] = useState(0);
  const [praposalList, setPraposalList] = useState([]);
  const [country, setCountry] = useState('');
  const [workTypeId, setWorkTypeId] = useState('');
  const [resetTrue, setResetTrue] = useState(false);
  const [pageLimit, setPageLimit] = useState(process.env.REACT_APP_PAGINATION_LIMIT);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(process.env.REACT_APP_PAGINATION_CURRENT_PAGE);
  const [showloader, setloader] = useState(false);
  const [favloader, setFavloader] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [modelOpenViewPlan, setModdelOpenViewPlan] = useState(false);
  const [selectedData, setSelectedData] = useState('');

  useEffect(() => {
    if(currentPage > 0){
      getPraposalList();
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCountrySelect = (country) => {
    setCountry(country);
    setResetTrue(false)
  };
  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false)
  }
  const handleReset = () => {
    setResetTrue(true);
    setCountry('');
    setWorkTypeId('');
  };

  useEffect(() => {
    if (resetTrue) {
      getPraposalList();
    }
  }, [resetTrue]);

  const getPraposalList = () => {
    const queryParams = new URLSearchParams({
      county: country,
      worktype_highway_id: workTypeId,
      page: currentPage,
      limit: pageLimit
    });
    setloader(true);
    const currentTime = new Date().getTime();
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_PROPOSAL_LIST}?currentTime=${currentTime}&${queryParams}`)
      .then(response => {
        if (response.data?.data) {
          setPraposalList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setPraposalList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch(error => {
        toast.error(error?.message);
        setloader(false);
      });
  }

  const addFav = (callid, favorite) => {
    setFavloader(true);
    const payload = {
      project_id: callid,
      type: "DOT",
      action: favorite
    }

    AxiosInstance.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_FAVORITE}`, payload)
      .then(response => {
        setFavloader(false);
        toast.success(response.data?.message);
        getPraposalList();
      })
      .catch(error => {
        toast.error(error?.message);
        setFavloader(false);
      })
  }
  const handleModelOpen = (callId) => {
    setSelectedData(callId);
    setModelOpen(true);
  }
  const handleModelOpenViewPlan = (callId) => {
    setSelectedData(callId);
    setModdelOpenViewPlan(true)
  }
  const handleModelClose = () => setModelOpen(false);
  const handleModelCloseViewPlan = () => setModdelOpenViewPlan(false);
  return (
    <>
      <div className="row mb-2">
        <div className="col-12 col-md-auto">
          <span
            className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
            id="basic-addon1">Filter:</span>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div>
              <WorkTypeFilter onSelectWorkType={handleWorkTypeSelect} reset={resetTrue} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div>
              <CountryFilter onSelectCountry={handleCountrySelect} reset={resetTrue} />
            </div>
          </div>
        </div>
        <div className="col-6 col-md mb-2">
          <div className="input-group mb-3">
            <button className="form-control btn btn-primary text-uppercase me-2 fw-bolder rounded-pill" onClick={() => getPraposalList()}>Search</button>
          </div>        </div>
        <div className="col-6 col-md mb-2">
          <div className="input-group mb-3">
            <button className="form-control btn btn-link text-uppercase me-2 text-danger text-start" onClick={() => handleReset()}>Reset</button>
          </div>
        </div>
      </div>

      <div className="clearfix mb-2">
        <span className="me-3 point-16">
          <span className="text-secondary fw-bolder text-uppercase point-16 me-2">Bidders List</span>
          <span className="point-16 fw-bolder text-uppercase text-primary me-2 d-none d-md-inline-block">
            |</span>
          <br className="d-block d-md-none" />

          <span className="point-16 fw-bolder text-uppercase text-secondary me-2">As Read Bids</span>
          <span className="point-16 fw-bolder text-uppercase text-primary me-2 d-none d-md-inline-block">
            |</span>
          <br className="d-block d-md-none" />
          <span className="text-secondary fw-bolder text-uppercase point-16">Notice to Contractors</span>
        </span>

        <span className="float-md-end d-block d-md-inline-block">
          <span className="point-12 text-black-50 fw-light"><span className="point-18"><i className="bi bi-star"></i></span>
            <span className="text-uppercase fw-bold">&nbsp;Save to Dashboard</span></span>
        </span>
        <span className="float-md-end me-3 point-16 d-block d-md-inline-block listMap">
          <span className="text-secondary fw-bolder text-uppercase point-16" style={{ marginLeft: "-84%" }}>View as:</span>
          <ul className="nav align-items-center" style={{ marginTop: "-27%" }}>
            <li className="nav-item">
              <Link
                className={`nav-link fw-bolder text-uppercase point-16 text-black`}
                to=""
                title="list"
              >
                List
              </Link>
            </li>
            <span className="point-16 fw-bolder mx-2">|</span>
            <li className="nav-item">
              <Link
                className={`nav-link fw-bolder text-uppercase point-16 text-secondary`}
                to="/map"
                title="Map"
              >
                Map
              </Link>
            </li>
          </ul>
        </span>
      </div>
      <div className="tab-content mt-3">
        <div className={`tab-pane fade ${nestedActiveTab === 0 ? 'show active' : ''}`} id="list">
          {!showloader && !favloader ? <ul className="list-group py-3">
            {praposalList.length && praposalList ? praposalList
              .map((data, i) => (
                <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer" key={i}>
                  <div className="d-md-flex justify-content-between align-items-start">
                    <div className="me-auto mb-2">
                      <span className="fw-bold point-22">{data?.county} &nbsp;</span>
                      <span className="point-22 text-black-50 fw-light mb-2">
                        {data?.favorites === "0" ? <i className="bi bi-star fs-6" onClick={() => addFav(data?.callid, data?.favorites)}></i>
                          : <img src={favoriteImg} alt="fav" onClick={() => addFav(data?.callid, data?.favorites)} />
                        }
                      </span>
                    </div>
                    <div className="mb-2">
                      <a
                        className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope
                        of Work</a>
                    </div>
                  </div>
                  <p className="lh-lg">
                    <span className="fw-bold">DBE Goal:</span> 18.1%
                    <span className="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                    <br className="d-block d-md-none" />

                    <span className="fw-bold">Call:</span> {data?.Call_Order}
                    <span className="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                    <br className="d-block d-md-none" />
                    <span className="fw-bold text-decoration-underline" onClick={() => handleModelOpen(data?.callid)} >List of Bid Items</span>
                    <span className="text-danger fw-bolder">&nbsp;|&nbsp;</span>
                    {data?.bidderDataAvailable > 0 ?
                      <span className="fw-bold text-decoration-underline" onClick={()=>handleModelOpenViewPlan(data?.callid)}>View Plan Holders</span>
                      :
                      <span className="fw-bold text-decoration-underline text-secondary">View Plan Holders</span>
                    }
                    <br />
                    <span className="fw-bold">Description:</span>{data?.Ldescription} <br />
                    <span className="fw-bold">Subs/Suppliers: Opt In</span>
                    <span className="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                    <br className="d-block d-md-none" />
                    <span>See <span className="fw-bold">0</span> Opt-in Records</span>
                    <br className="d-block d-md-none" />
                    <span className="fw-bold">&nbsp;Primes: Advertise for help</span>
                    <span className="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                    <br className="d-block d-md-none" />
                    <span><span className="fw-bold">0</span> Prime contractors looking for
                      help</span>
                  </p>
                </li>
              )) :
              <div className="d-md-flex justify-content-center">
                No Data Found.
              </div>
            }
          </ul> : <div className="loader">
            <img src={loader} alt="loading" />
          </div>}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageLimit={pageLimit}
        onPageChange={handlePageChange}
      />
     {modelOpen && <ListOfBidModel
        open={modelOpen}
        handleClose={handleModelClose}
        callId={selectedData}
      />}
     {modelOpenViewPlan && <ViewPlanHolderModel
        open={modelOpenViewPlan}
        handleClose={handleModelCloseViewPlan}
        callId={selectedData}
      />}
    </>
  );
}

export default ProposalDot;
