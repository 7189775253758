import React, { useEffect, useState } from "react";
import AxiosInstance from "../../utils/axiosInstance";
import { setLocalStoarge } from "../../common/allFunctions";


export default function CountryFilter({ onSelectCountry, reset }) {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("countyList") || "[]");
    setCountries(data);
    if (data.length === 0) {
      getCountry();
    }
  }, []);

  useEffect(() => {
    if (reset) {
      setSelectedCountry('')
    }
  }, [reset]);

  const getCountry = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_COUNTRY}`)
      .then(response => {
        if (response?.data?.data) {
          setCountries(response?.data?.data);
          setLocalStoarge("countyList", JSON.stringify(response?.data?.data))
        }
      })
      .catch(error => {
        console.log(error?.message);
      });
  }
  const handleChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    onSelectCountry(country);
  };

  return (
    <select
      className="form-select border-top-0 border-start-0 border-end-0 border-3 border-secondary rounded-0"
      aria-label="Default select example"
      value={selectedCountry}
      onChange={handleChange}
    >
      <option value="" disabled>Country</option>
      {countries.map((country, i) => (
        <option key={i}>
          {country.countyName}
        </option>
      ))}
    </select>
  );
};