import React from "react";


export default function ProfileSecurity() {
  return (
    <>
        <h2
          class="text-primary fw-bolder point-32 mb-3 text-uppercase"
        >
          Security
        </h2>
        <h3
          class="text-secondary fw-bolder point-16 text-uppercase mt-4"
        >
          Change Password:
        </h3>
        <form class="mb-4">
          <div class="row mb-4">
            <div class="col">
              <input
                type="password"
                class="form-control border border-secondary border-3 rounded-0"
                placeholder="Current Password"
                aria-label="Current Password"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <input
                type="password"
                class="form-control border border-secondary border-3 rounded-0"
                placeholder="Enter New Password"
                aria-label="Enter New Password"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <input
                type="password"
                class="form-control border border-secondary border-3 rounded-0"
                placeholder="Confirm New Password"
                aria-label="Confirm New Password"
              />
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-secondary rounded-5 fw-bold point-18 text-uppercase"
          >
            Save Changes
          </button>
        </form>
        <div>
          <h3
            class="text-secondary fw-bolder point-16 text-uppercase"
          >
            Delete Account
          </h3>
          <p class="text-secondary point-14">
            Deleting your account is a permanent action and cannot
            be undone. If you are sure you want to delete your
            account, select the button below.
          </p>
          <p
            class="text-secondary fw-bolder point-14 text-uppercase"
          >
            <a
              href="#"
              class="text-secondary fw-bolder point-14 text-uppercase mt-4 text-decoration-none"
            >I understand, delete my account</a>
          </p>
        </div>
    </>
  );
}
