import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function StateCityCountryPage() {
  return (
    <>
      <Header />
      <div class="bg-primary pt-5 pb-20">
        <div class="container">
          <div class="row">
            <div class="col col-md-auto bg-primary mb-3">
              <p class="text-white point-22">Bid Opportunities</p>
              <h1 class="text-secondary fw-boldest point-42">
                STATE/<br class="d-block d-md-none" />COUNTY/<br class="d-block d-md-none" />CITY
              </h1>
            </div>
            <div class="col text-end">&nbsp;</div>
          </div>
        </div>
      </div>
      <div class="bg-white py-3">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12 col-md-5">
                      <div class="input-group mb-3">
                        <span class="input-group-text text-uppercase text-secondary fw-bold border-0 bg-white rounded-0" id="basic-addon1">Filter:</span>
                        <select class="form-select border-top-0 border-start-0 border-end-0 border-3 border-secondary rounded-0" aria-label="Default select example">
                          <option selected="">State</option>
                          <option value="1">County</option>
                          <option value="2">City</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6 col-md mb-2">
                      <div class="input-group mb-3">
                        <input type="button" class="form-control btn btn-link text-uppercase me-2 text-danger text-start" placeholder="Reset" aria-label="Reset" value="Reset" aria-describedby="basic-addon1" />
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control fw-bolder text-white bg-secondary rounded-0 border border-1 border-secondary placeholder-white point-18" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" />
                        <button class="btn btn-primary text-secondary rounded-0 border-0" type="button" id="button-addon2">
                          <span class="point-20"><svg xmlns="http://www.w3.org/2000/svg" width="21.208" height="22.989" viewBox="0 0 21.208 22.989">
                            <g id="Group_35" data-name="Group 35" transform="translate(-1193.792 -328)">
                              <g id="Ellipse_15" data-name="Ellipse 15" transform="translate(1199 328)" fill="none" stroke="#9fafca" stroke-width="3">
                                <circle cx="8" cy="8" r="8" stroke="none"></circle>
                                <circle cx="8" cy="8" r="6.5" fill="none"></circle>
                              </g>
                              <path id="Path_120" data-name="Path 120" d="M1208.54,5578.4l-6.2,7.762" transform="translate(-6.44 -5237.284)" fill="none" stroke="#9fafca" stroke-linecap="round" stroke-width="3"></path>
                            </g>
                          </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix">
                  <span class="float-end">
                    <span class="point-12 text-black-50 fw-light"><span class="point-18"><i className="bi bi-star fs-6"></i></span>
                      <span class="text-uppercase fw-bold">&nbsp;Save to Dashboard</span></span>
                  </span>
                </div>
                <div class="">
                  <ul class="list-group py-3">
                    <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer">
                      <div class="d-md-flex justify-content-between align-items-start">
                        <div class="me-auto mb-2">
                          <span class="fw-bold point-22">Project Name Here&nbsp;</span>
                          <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                        </div>
                        <div class="mb-2">
                          <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                        </div>
                      </div>
                      <p class="lh-lg">
                        <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry.<br />
                        <span class="fw-bold">Subs/Suppliers: Opt In</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">Primes: Advertise for help</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                          help</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="">
                  <ul class="list-group py-3">
                    <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
                      <div class="d-md-flex justify-content-between align-items-start">
                        <div class="me-auto mb-2">
                          <span class="fw-bold point-22">Project Name Here&nbsp;</span>
                          <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                        </div>
                        <div class="mb-2">
                          <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                        </div>
                      </div>
                      <p class="lh-lg">
                        <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry.<br />
                        <span class="fw-bold">Subs/Suppliers: Opt In</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">Primes: Advertise for help</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                          help</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="">
                  <ul class="list-group py-3">
                    <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
                      <div class="d-md-flex justify-content-between align-items-start">
                        <div class="me-auto mb-2">
                          <span class="fw-bold point-22">Project Name Here&nbsp;</span>
                          <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                        </div>
                        <div class="mb-2">
                          <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                        </div>
                      </div>
                      <p class="lh-lg">
                        <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry.<br />
                        <span class="fw-bold">Subs/Suppliers: Opt In</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">Primes: Advertise for help</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                          help</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="">
                  <ul class="list-group py-3">
                    <li class="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0">
                      <div class="d-md-flex justify-content-between align-items-start">
                        <div class="me-auto mb-2">
                          <span class="fw-bold point-22">Project Name Here &nbsp;</span>
                          <span class="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                        </div>
                        <div class="mb-2">
                          <a class="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                        </div>
                      </div>
                      <p class="lh-lg">
                        <span class="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry.<br />
                        <span class="fw-bold">Subs/Suppliers: Opt In</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">See <span class="fw-bold">0</span> Opt-in Records</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold">Primes: Advertise for help</span>
                        <span class="text-danger fw-bolder d-none d-md-inline-block">|</span>
                        <br class="d-block d-md-none" />
                        <span class="fw-bold"><span class="fw-bold">0</span> Prime contractors looking for
                          help</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <nav aria-label="Page navigation example" className="clearfix">
                    <ul className="pagination float-end">

                      <li className="page-item">
                        <a className="page-link border-0 fw-bolder" href="#">1</a>
                      </li>
                      <li className="page-item"><a className="page-link border-0" href="#">2</a></li>
                      <li className="page-item"><a className="page-link border-0" href="#">3</a></li>
                      <li className="page-item"><a className="page-link border-0" href="#">Next</a></li>
                    </ul>
                  </nav>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
