import { Link, useLocation, useNavigate } from 'react-router-dom'
import img from '../assets/header-img.svg'
import footerimg from '../assets/footer-img.svg'

export default function Header() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const logOut=(e)=>{
    e.preventDefault();
    window.localStorage.removeItem('accessToken');
    navigate('/login')
  }
  const location = useLocation();

  return (
    <header>
      {accessToken && location?.pathname!=='/' && location?.pathname!=='/login' && location?.pathname!=='/signup'?
        <div className="bg-primary py-3"><div className="container">
          <div className="row">
            <div className="col-4 col-md-4">
              <img src={footerimg} alt="logo" width={55}/>
            </div>
            <div className="col-4 d-md-none">
              <a className="nav-link bg-danger rounded-5 text-white text-uppercase fw-bold point-16 text-center" href="#">Log Out</a>
            </div>
            <div className="col-12 col-md-8">
              <div className="float-md-end pt-3">
                <ul className="nav text-white ms-auto">
                  <li className="nav-item">
                    <Link className={location?.pathname==='/dashboard'? `nav-link text-white text-uppercase fw-bold red-underline point-16` : `nav-link text-white text-uppercase fw-bold point-16`} to="/dashboard">My Dashboard</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={location?.pathname==='/contactus'? `nav-link text-white text-uppercase fw-bold red-underline point-16` : `nav-link text-white text-uppercase fw-bold point-16`} to="/contactus">Contact Us</Link>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <Link className="nav-link bg-danger rounded-5 text-white text-uppercase fw-bold point-16" onClick={(e)=>logOut(e)}>Log Out</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
        :
        <div className="container pt-4">
          <div className="row">
            <div className="col col-md-6">
              <img src={img} alt="logo" className='logo' onClick={() => navigate('/')} />
            </div>
            <div className="col">
              <div className="float-md-end">
              <ul className="nav">
                  <li className="nav-item">
                    <Link
                      className={location?.pathname==='/login'? `nav-link text-white text-uppercase fw-bold red-underline point-16` : `nav-link text-white text-uppercase fw-bold point-16`}
                      to="/login"
                    >
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={location?.pathname==='/signup'? `nav-link text-white text-uppercase fw-bold red-underline point-16` : `nav-link text-white text-uppercase fw-bold point-16`}
                      to="/signup"
                    >
                      Sign Up
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={location?.pathname==='/contactus'? `nav-link text-white text-uppercase fw-bold red-underline point-16` : `nav-link text-white text-uppercase fw-bold point-16`}
                      to="/contactus"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      }
    </header>
  );
}
