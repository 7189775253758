import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DashboardShortCuts from "../components/DashboardShortCuts";
import wahLogo from "../assets/WAH-Logo-ALDOT-Approved.png"
import progrssGraph from "../assets/progress-graph-icon.svg"
import downloadImg from "../assets/download-img.svg"
export default function WAH() {

  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md bg-primary mb-3">
              <h1 className="text-secondary fw-boldest point-42">My Dashboard</h1>
            </div>
            <div className="col-6 col-md-auto text-end">
              <img
                src={wahLogo}
              />
            </div>
            <div className="col-6 col-md-auto text-end pt-md-4">
              <span
                className="text-secondary fw-bolder text-uppercase point-16 me-md-3"
              >User Profile</span>

            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3 clearfix">
        <div className="container mt-n20">
          <div className="row">
            <div className="col-12 col-md-8 pb-4">
              <div
                className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0"
              >
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold"
                      >
                        DBE PERFORMANCE PLAN
                      </h3>
                    </div>
                    <div className="col-auto bg-secondary">
                      <div className="rounded-pill bg-secondary w-100">
                        <div className="d-flex flex-column justify-content-center">
                        <img className="header-icon m-2" src={progrssGraph} alt="icon"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="mt-5">
                    <button
                      type="submit"
                      className="btn btn-primary text-white rounded-5 fw-bold point-16 px-4 me-3 text-uppercase"
                    >
                      Download 2024 plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
              <DashboardShortCuts />
          </div>
          <div className="row">
            <div className="col">
              <div
                className="card w-100 h-100 bg-white rounded-3 shadow-sm mb-4 border-0"
              >
                <div
                  className="card-header g-0 m-0 p-0 overflow-hidden border-bottom border-light border-2"
                >
                  <div className="row g-0">
                    <div className="col g-0">
                      <div
                        className="float-end text-secondary point-16 mb-2 fw-boldest pt-4 me-4"
                      >
                        + Add&nbsp; 
                        <span className="text-danger">contacts</span>
                      </div>
                      <h3
                        className="p-3 mb-0 pb-0 point-22 text-uppercase text-primary fw-bold"
                      >
                        Monitoring Reports
                      </h3>
                    </div>
                    <div className="col-auto bg-secondary">
                      <div className="rounded-pill bg-secondary w-100">
                        <div className="d-flex flex-column justify-content-center">
                          <img src={downloadImg} className="header-icon m-2" alt="logo"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <ul className="list-group py-3 pe-3 mh-20">
                    <li
                      className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0"
                    >
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col-12 col-md point-18 text-primary fw-bold mb-2"
                          >
                            CUFS
                          </div>

                          <div
                            className="col-12 col-md point-18 text-primary text-md-end"
                          >
                            <a
                              href="#"
                              className="text-decoration-none btn-with-svg point-18 btn rounded-pill btn-primary"
                            >View
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li
                      className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0"
                    >
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col-12 col-md point-18 text-primary fw-bold mb-2"
                          >
                            Economic Reports
                          </div>

                          <div
                            className="col-12 col-md point-18 text-primary text-md-end"
                          >
                            <a
                              href="#"
                              className="text-decoration-none btn-with-svg point-18 btn rounded-pill btn-primary"
                            >View
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li
                      className="list-group-item py-3 mb-3 d-flex justify-content-between align-items-start bg-light border border-1 rounded-0"
                    >
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col-12 col-md point-18 text-primary fw-bold mb-2"
                          >
                            Meeting & Attendance Records
                          </div>

                          <div
                            className="col-12 col-md point-18 text-primary text-md-end"
                          >
                            <a
                              href="#"
                              className="text-decoration-none btn-with-svg point-18 btn rounded-pill btn-primary"
                            >View
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
