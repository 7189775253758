import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AxiosInstance from "../utils/axiosInstance";
import Select from 'react-select';

export default function UploadingRequest() {
  const [highway, setHighway] = useState([]);
  const [building, setBuilding] = useState([]);
  const [options, setOptions] = useState([]);
  const [workTypeList, setWorkTypeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (selected) => {
    const workTypeData = transformWorkTypeData(workTypeList, selected);
    // setFormData((prevData) => ({ ...prevData, 'worktypes': workTypeData }));
    setSelectedOptions(selected);
  };
  useEffect(() => {
    const fetchAPIs = async () => {
      try {
        getWorkType();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchAPIs();
  }, []);
  const transformData = (items) =>
    items.map(({ id, name }) => ({
      value: id,
      label: name,
    })
    );

  const transformWorkTypeData = (originalData, selectedValues) => {
    const selectedIds = selectedValues.map(item => item.value);

    const result = Object.keys(originalData).reduce((acc, key) => {
      acc[key] = originalData[key]
        .filter(item => selectedIds.includes(item.id))
        .map(item => item.id);
      return acc;
    }, {});

    return result;
  };
  const getWorkType = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_WORKTYPE_API}`)
      .then(response => {
        setWorkTypeList(response.data?.data)
        setHighway(transformData(response.data?.data?.highway));
        setBuilding(transformData(response.data?.data?.building));
        setOptions([
          ...transformData(response.data?.data?.highway),
          ...transformData(response.data?.data?.building),
        ])
      })
      .catch(error => {
        console.log(error?.message);
      });
  }
  return (
    <>
      <Header />
      <div className="bg-primary py-3">
        <div className="container">
          <div className="row mt-10">
            <div className="col col-md-auto">
              <p className="text-white point-22">Bid Opportunities</p>
              <h1 className="text-secondary fw-boldest point-42">
                Uploading Request
              </h1>
            </div>
            <div className="col">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="bg-white py-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="fw-bold point-32 fw-bold">Get Started</h2>
              <p className="point-18">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor.
              </p>

              <form>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="First name"
                      aria-label="First name"
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Last name"
                      aria-label="Last name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Email"
                      aria-label="Email"
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Deadline Date for submitting quotes"
                      aria-label="Deadline Date for submitting quotes"
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="DBE Goal%"
                      aria-label="DBE Goal%"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control border border-secondary rounded-0"
                      placeholder="Project Title"
                      aria-label="Project Title"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <textarea
                      className="form-control border border-secondary rounded-0"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Project Description"
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                <div className="col-12 col-md-6 mb-3">
                  <label className="form-check-label" htmlFor="flexCheckDefault1">
                    <span className="fw-bold point-16 text-uppercase">Select Work Type</span>
                  </label>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#9FAFCA'
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        color: '#a71e1e',
                      })
                    }}
                    options={options}
                    isMulti
                    value={selectedOptions}
                    onChange={handleChange}
                    placeholder="Select work type..."
                    isSearchable
                  />
                </div>
                  <div className="col-12 col-md-6 mb-3 mt-4" >
                    <input
                      className="form-control border border-secondary rounded-0"
                      type="file"
                      id="formFile"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-secondary rounded-5 fw-semibold text-uppercase point-18"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
