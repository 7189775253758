import axios from "axios";
import toast from "react-hot-toast";
// Axios Interceptor Instance
const AxiosInstance = axios.create({
    baseURL: process.env.BASE_URL,
});
const API_STATUS_CODES = {
    SUCCESS: process.env.REACT_APP_STATUS_SUCCESS || 200,
    UNAUTHORIZED: process.env.REACT_APP_STATUS_FORBIDDEN || 401,
};
// Axios Interceptor: Header Method
AxiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        const accessToken = token ? token : null;

        // If token is present, add it to the request's Authorization Header
        if (accessToken && config.headers) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

// Axios Interceptor: Response Method
AxiosInstance.interceptors.response.use(
    (response) => {
        // Can modify the response if needed
        if (response?.data?.statusCode === parseInt(API_STATUS_CODES.SUCCESS)) {
            return response;
        }
        return Promise.reject(response);
    },
    (error) => {
        // Handle response errors here
        if (error?.response?.data?.statusCode === parseInt(API_STATUS_CODES.UNAUTHORIZED)) {
            toast.error(error?.response?.data?.message);
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default AxiosInstance;
