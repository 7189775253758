import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import ProposalDot from "./ProposalDot";
import BidMatching from "./BidMatchingDot";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";

export default function Dot() {
    const [activeTab, setActiveTab] = useState(0);
    const location = useLocation();

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        if (location.state?.data) {
            setActiveTab(location.state?.data);
        }
    }, [location.state]);

    return (
        <>
            <Header />
            <div className="bg-primary pt-5 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-auto bg-primary mb-3">
                            <p className="text-white point-22">Bid Opportunities</p>
                            <h1 className="text-secondary fw-boldest point-42">DOT</h1>
                            <div className="dotPage">
                                <ul className="nav nav-tabs align-items-center">
                                    <li className="nav-item">
                                        <Link
                                            className={`nav-link fw-bolder text-uppercase point-16 ${activeTab === 0 ? 'text-white' : 'text-secondary'}`}
                                            title="Proposals"
                                            onClick={() => handleTabClick(0)}
                                        >
                                            Proposals
                                        </Link>
                                    </li>
                                    <span className="point-16 fw-bolder text-danger mx-2">|</span>
                                    <li className="nav-item">
                                        <Link
                                           className={`nav-link fw-bolder text-uppercase point-16 ${activeTab === 1 ? 'text-white' : 'text-secondary'}`}
                                            title="Bid Matching"
                                            onClick={() => handleTabClick(1)}
                                        >
                                            Bid Matching
                                        </Link>
                                    </li>
                                    <span className="point-16 fw-bolder text-danger mx-2">|</span>
                                    <li className="nav-item">
                                        <Link
                                            className={`nav-link fw-bolder text-uppercase point-16 ${activeTab === 2 ? 'text-white' : 'text-secondary'}`}
                                            title="Agency Information"
                                            onClick={() => handleTabClick(2)}
                                        >
                                            Agency Information
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col text-end">
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-3">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                                <div className="card-body p-3">
                                    <div className="tab-content mt-3">
                                        <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`} id="proposals">
                                            <ProposalDot />
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} id="bidMatching">
                                            <BidMatching />
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} id="agencyInfo">
                                            <p>Agency Information content goes here.</p>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}