import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function MarketingPage() {

  return (
    <>
      <div className="d-flex flex-column">
        <div className="bg-image">
          <Header />
          <div className="container">
            <div className="row mt-15">
              <div className="col col-md-auto bg-primary">
                <h1 className="text-white p-3 fw-bold point-22">
                  Updated: April 5, 2024
                </h1>
              </div>
              <div className="col">&nbsp;</div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="py-5">
                  <h2 className="text-secondary fw-bolder point-42">
                    Search More.<br />Bid Less.
                  </h2>
                  <p className="point-18">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 bg-light">
                <div className="">
                  <ul className="list-group py-4 mh-20">
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Backfill</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Base</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Bridge</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Clearing</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Concrete</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Culvert</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Framing</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Electrical</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                    <li
                      className="list-group-item mb-3 d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold point-22">Water Lines</div>
                      </div>
                      <span
                        className="badge bg-secondary text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16"
                      >view</span> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
