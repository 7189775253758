export const getLocalStoarge = (key_id) =>{
    let values = localStorage.getItem(key_id);
    values = (values)?values: false;
    return values;
  }
  
  export const setLocalStoarge = (key_id, items) =>{
    localStorage.setItem(key_id, items);
    return true;
  }
  
  export const removeLocalStoarge = () =>{
    localStorage.clear();
    return true;
  }
  
  export const removeLocalStoargeKey = (key_id) =>{
    localStorage.removeItem(key_id);
    return true;
  }